<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">ユーザー紐付け - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                v-model="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>メール送信</div>
            <div>
              <b-form-select
                v-model="search.doneMail"
                :options="statusList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>紐付け</div>
            <div>
              <b-form-select
                v-model="search.doneTie"
                :options="statusList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div v-if="dbAppList.length > 0" class="flex flexCenter">条件に一致するデータがありません。</div>
            <div v-if="dbAppList.length === 0" class="flex flexCenter">選択年度の紐付けが必要な採択された申請が表示されます。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedIdList($event)"
                :checked="selectedIdList"
                :value="row.item.id"
                :disabled="row.item.done_tie === 1"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="this.selectedIdList.length < 1"
        to="/cms/university/tie/mail">メール送信</b-button>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
// import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'CmsUserTieList',
  data() {
    return {
      search: {
        year: null,
        name: '',
        series: 5,
        code: '',
        doneMail: null,
        doneTie: null,
      },
      rawSeriesList: [],
      //   status: CONST_STATUS.APP_STATUS,
      statusList: [
        { value: 0, text: '未' },
        { value: 1, text: '済' },
      ],
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'university', label: '大学名', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'done_mail_text', label: 'URL発行', sortable: true },
        { key: 'done_tie_text', label: '紐付け', sortable: true },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      yearList: [],
      dbAppList: [],
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    // eslint-disable-next-line
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const seriesSort = search.series === null || lineData.series_id === Number(search.series);
      const codeSort = search.code === '' || (lineData.code && lineData.code.includes(search.code));
      const doneMail = search.doneMail === null || lineData.done_mail === Number(search.doneMail);
      const doneTie = search.doneTie === null || lineData.done_tie === Number(search.doneTie);
      return nameSort && seriesSort && codeSort && doneMail && doneTie;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.$store.commit('cmsUniversityTieSearch/initSelectedId');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsUniversityTieSearch/setFilterdIdList', filteredItems);
    },
    async listFetch() {
      const param = { year: this.search.year };

      const response = await api.send('/api/cms/university/tie/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbAppList = response.data.list;
      this.rawSeriesList = response.data.seriesList;
      this.yearList = response.data.yearList;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.totalRows = this.dbAppList.length;
      this.$store.commit('cmsUniversityTieSearch/setFilterdIdList', this.dbAppList);
    },
    setFilter(key, value) {
      this.$set(this.search, key, value);
    },
    allSelect() {
      this.$store.commit('cmsUniversityTieSearch/allSelectedId');
    },
    allRemove() {
      this.$store.commit('cmsUniversityTieSearch/initSelectedId');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsUniversityTieSearch/addSelectedId', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsUniversityTieSearch/removeSelectedId', appId);
      });
    },
    updateSelectedIdList(list) {
      this.$store.commit('cmsUniversityTieSearch/setSelectedId', list);
    },
  },
  computed: {
    seriesList() {
      if (!this.rawSeriesList) {
        return [];
      }
      return this.rawSeriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
    },
    appList() {
      if (!this.dbAppList) {
        return [];
      }
      const appList = this.dbAppList.map((app) => {
        let name = '';
        let kana = '';
        if (app.ans_name && app.ans_kana) {
          app.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          app.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        return {
          id: app.id,
          series_id: app.series_id,
          year: app.uear,
          kana,
          name,
          university: app.ans_belongs.field_answer_text,
          apptype: app.name,
          code: app.code,
          done_mail: app.done_mail ? 1 : 0,
          done_mail_text: app.done_mail ? '済' : '未',
          done_tie: app.done_tie ? 1 : 0,
          done_tie_text: app.done_tie ? '済' : '未',
        };
      });
      return appList;
    },
    selectedIdList() {
      return this.$store.state.cmsUniversityTieSearch.selectedIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsUniversityTieSearch.filterdIdList;
    },
    totalRow() {
      return this.appList.length;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.listFetch();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.$store.commit('cmsUniversityTieSearch/initSelectedId');
    await this.listFetch();
  },
};
</script>

<style>
  .cmsAppTable thead th:nth-of-type(1) {
    width: 70px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
